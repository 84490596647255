<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">
                {{ id ? "Edit" : "Add" }}
                {{ user_type == "APPOINTMENT_OFFICIAL" ? "CG " : "" }}
                {{
                  $helperService.getTitleCase(
                    user_type.replaceAll("FIELD", "Wellfare")
                  )
                }}
              </h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-10 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Name</label>
                            <span class="text-danger">*</span>
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Enter Full Name"
                              v-model="detail.name"
                              rules="required:Name"
                              :validateOnInput="true" />
                            <ErrorMessage name="name" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6">
                          <div class="form-group">
                            <label>Mobile number</label>

                            <Field
                              type="text"
                              name="phone"
                              placeholder="Phone"
                              v-slot="{ field }"
                              v-model="detail.mobile"
                              rules="required:mobile number"
                              :validateOnInput="true">
                              <PhoneCode
                                id="mobile"
                                placeholder="Mobile Number"
                                v-model:countryCode="detail.country_code"
                                v-model="detail.phone"
                                v-model:dialCode="detail.dial_code"
                                v-bind="field" />
                              <ErrorMessage name="phone" class="text-danger" />
                            </Field>
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Password </label>
                            <span class="text-danger">*</span>
                            <!-- <Field type="password" name="password" class="form-control" placeholder="Password"
                              v-model="detail.password" rules="required:password|minLength:6" :validateOnInput="true" /> -->
                            <Field
                              :type="passwordFieldType"
                              name="password"
                              class="form-control"
                              placeholder="Password"
                              rules="required:password|minLength:6"
                              :validateOnInput="true"
                              v-model="detail.password" />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              ">
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                            <ErrorMessage name="password" class="text-danger" />
                          </div>
                        </div>
                        <div class="col-lg-6 col-sm-6" v-if="!id">
                          <div class="form-group">
                            <label>Confirm Password</label>
                            <span class="text-danger">*</span>
                            <!-- <Field type="password" placeholder="Confirm Password" name="name_ccpassword"
                              class="form-control" rules="required:confirm password|confirmed:@password"
                              :validateOnInput="true" /> -->
                            <Field
                              :type="passwordFieldType2"
                              placeholder="Confirm Password"
                              name="name_ccpassword"
                              class="form-control"
                              rules="required:confirm password|confirmed:@password"
                              v-model="detail.confirm_password"
                              :validateOnInput="true" />
                            <span
                              style="
                                position: absolute;
                                right: 20px;
                                top: 40px;
                                z-index: 999;
                              ">
                              <a
                                class="psw-icon cursor-pointer"
                                v-on:click="switchPasswordVisibility"
                                ><img :src="image" width="20"
                              /></a>
                            </span>
                            <ErrorMessage
                              name="name_ccpassword"
                              class="text-danger" />
                          </div>
                        </div>
                        <div
                          v-if="id"
                          class="col-lg-12 col-sm-12 mt-2 d-flex justify-content-end">
                          <button
                            type="button"
                            id="forgot-button"
                            class="fill-btn px-4 ml-3"
                            v-on:click="showChangePasswordPopup()">
                            Change Password
                          </button>
                        </div>
                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TextModal ref="textModal">
      <div class="modal-header">
        <h5 class="modal-title">Change Password</h5>
        <button
          type="button"
          class="close"
          v-on:click="$refs.textModal.closeModal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="changePasswordForm" @submit="changePassword">
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>New Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType"
              name="name_password"
              class="form-control"
              placeholder="New Password"
              v-model="password"
              rules="required:password|minLength:6"
              :validateOnInput="true" />
            <span
              style="position: absolute; right: 22px; top: 40px; z-index: 999">
              <a class="psw-icon cursor-pointer" v-on:click="switchVisibility"
                ><img :src="image" width="20"
              /></a>
            </span>
            <ErrorMessage name="name_password" class="text-danger" />
          </div>
        </div>
        <div class="col-lg-12 col-sm-12">
          <div class="form-group">
            <label>Confirm Password</label>
            <span class="text-danger">*</span>
            <Field
              :type="passwordFieldType2"
              placeholder="Confirm Password"
              name="name_ccpassword"
              class="form-control"
              rules="required:confirm password|confirmed:@name_password"
              :validateOnInput="true" />
            <span
              style="position: absolute; right: 20px; top: 40px; z-index: 999">
              <a
                class="psw-icon cursor-pointer"
                v-on:click="switchPasswordVisibility"
                ><img :src="images" width="20"
              /></a>
            </span>
            <ErrorMessage name="name_ccpassword" class="text-danger" />
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="change-password-button"
            style="width: 20%; border-radius: 5px">
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="$refs.textModal.closeModal"
            style="width: 20%; border-radius: 5px">
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
  </div>
</template>
<script>
import TextModal from "@/components/TextModal";
import { Form, Field, ErrorMessage } from "vee-validate";
import PhoneCode from "../../components/PhoneCodePicker/PhoneCode.vue";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    ErrorMessage,
    TextModal,
    PhoneCode,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        password: "",
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      user_type: "",
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getUserType();
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    getUserType() {
      if (window.location.pathname == "/add-field-inspector-offcial") {
        this.user_type = "FIELD_INSPECTOR";
      } else if (window.location.pathname == "/add-settlement-offcial") {
        this.user_type = "SETTLEMENT_OFFICIAL";
      } else if (window.location.pathname == "/add-appointment-offcial") {
        this.user_type = "APPOINTMENT_OFFICIAL";
      }
    },
    getDetail() {
      this.$api
        .getAPI({
          _action: "/user/" + this.id,
        })
        .then((res) => {
          if (res && res.result) {
            this.detail = res.result;
            this.user_type = res.result.role;
          }
        })
        .catch(() => {});
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/user",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.detail.role = this.user_type;
        this.$api
          .postAPI({
            _action: "/user",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    showChangePasswordPopup() {
      this.$refs.textModal.showModal();
      this.$refs.changePasswordForm.resetForm();
    },
    changePassword() {
      var obj = {};
      obj.password = this.password;
      this.$api
        .putAPI({
          _action: "/change/password/" + this.id,
          _body: obj,
          _buttonId: "change-password-button",
        })
        .then(() => {
          this.$refs.textModal.closeModal();
          this.$notify({
            type: "success",
            text: "Password changed successfully",
          });
        })
        .catch(() => {});
    },
    switchVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
      this.image =
        this.image === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
    switchPasswordVisibility() {
      this.passwordFieldType2 =
        this.passwordFieldType2 === "password" ? "text" : "password";
      this.images =
        this.images === "/images/show.png"
          ? "/images/hide.png"
          : "/images/show.png";
    },
  },
};
</script>
