<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row col-lg-6 col-sm-6 my-auto">
            <h4 class="mb-0 lg-bld">Manage Feedback Comments</h4>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-2">
            <div class="row">
              <!-- <div class="col-lg-6 col-sm-6 form-group row">
                <div class="has-search ml-3">
                  <i
                    class="fa fa-search"
                    style="position: absolute; margin: 10px"
                    aria-hidden="true"></i>
                  <input
                    type="text"
                    class="form-control"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search"
                    v-model="searchObj.keyword" />
                </div>
                <a
                  class="fill-btn btn-style cursor-pointer ml-3"
                  v-on:click="getList(0)"
                  >Search</a
                >
                <a
                  class="fill-btn btn-style cursor-pointer"
                  v-on:click="resetFilter"
                  v-show="searchObj.keyword"
                  >Reset</a
                >
              </div> -->
              <!-- <div
                style="cursor: pointer"
                class="col-lg-6 col-sm-6 txt-right form-group">
                <a
                  class="fill-btn cursor-pointer"
                  v-on:click="showCommentPopup()"
                  >+ Add Comment</a
                >
              </div> -->
            </div>
            <div class="card border mb-0 radius-15 p-1">
              <div class="card-body p-0" style="font-weight: bold">
                <p>
                  <span style="color: red">Feedback</span>:
                  <span>{{ feedback?.name }}</span> |
                  <span>{{ feedback?.phone }}</span> |
                  <span>{{ feedback?.message }}</span
                  >.
                </p>
              </div>
            </div>
            <div class="col-12 card border mb-0 radius-15">
              <div class="chat-container mt-3">
                <div class="chat-window" style="height: 56vh">
                  <div v-for="(data, index) in list" :key="index">
                    <div
                      class="col-12"
                      style="width: 100vw"
                      v-if="data.added_by">
                      <div
                        class="message received"
                        style="float: left; width: 100vw">
                        <p>{{ data.message }}</p>
                        <span class="timestamp">10:00 AM</span>
                      </div>
                    </div>
                    <div
                      class="col-12"
                      style="width: 100vw"
                      v-if="!data.added_by">
                      <div
                        class="message sent"
                        style="float: right; width: 100vw">
                        <p>{{ data.message }}</p>
                        <span class="timestamp">10:01 AM</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="chat-input">
                  <input
                    type="text"
                    v-model="resultDetail.message"
                    placeholder="Type a message..." />
                  <button v-on:click="addComment()">Send</button>
                </div>
              </div>
              <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            </div>

            <!-- <div class="card border mb-0 radius-15">
              <div class="card-body p-0 pb-4">
                <div class="table-responsive">
                  <div class="tableFixHead">
                    <table
                      class="table table-striped table-bordered"
                      role="grid"
                      aria-describedby="example_info"
                      id="members-list">
                      <thead>
                        <tr role="row">
                          <th>Sr. No.</th>
                          <th
                            class="sorting"
                            id="id-name"
                            v-on:click="sorting('employee_name', 'id-name')">
                            Added By
                          </th>
                          <th
                            class="sorting"
                            id="id-department"
                            v-on:click="sorting('department', 'id-department')">
                            Message
                          </th>
                        </tr>
                      </thead>
                      <tbody v-show="!loader">
                        <tr v-for="(data, index) in list" :key="index">
                          <td style="text-align: left">
                            {{
                              ($refs.listPagination.currentPage - 1) * 10 +
                              index +
                              1
                            }}
                          </td>
                          <td style="text-align: left">
                            {{ data.added_by ? data.added_by.name : "" }}
                          </td>
                          <td style="text-align: left">
                            {{ data.message }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <ErrorComponent @retry="getList(0)" ref="errorComponent" />
                  </div>
                </div>
                <div class="row mx-0 mb-4">
                  <div class="col-lg-12 mt-5 text-center">
                    <div class="page-bottom-pagination text-right">
                      <Pagination
                        @page-change="pageChange"
                        @items-per-page-change="itemsPerPageChange"
                        ref="listPagination" />
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <div
          class="text-center"
          style="margin-top: 20%"
          v-show="accessObject?.is_view == 0 || isShowAccessDeniedMessage">
          <h3>You do not have access to view admin</h3>
        </div>
      </div>
    </div>
    <TextModal ref="CommentModel">
      <div class="modal-header">
        <h5 class="modal-title">Commnent</h5>
        <button type="button" class="close" v-on:click="CloseResultpopup()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Form ref="commentForm" @submit="addComment()">
        <div class="col-lg-12 col-sm-12">
          <div class="col-12 row">
            <div class="col-lg-12 col-sm-12">
              <div class="form-group">
                <label>Commnent</label>
                <span class="text-danger">*</span>
                <Field
                  as="textarea"
                  name="Comment"
                  class="form-control"
                  placeholder="Enter Comment"
                  style="height: 283px !important"
                  v-model="resultDetail.message"
                  rules="required:Comment"
                  :validateOnInput="true" />
                <ErrorMessage name="Comment" class="text-danger" />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer pb-0">
          <button
            class="fill-btn"
            id="save-button"
            style="width: 20%; border-radius: 5px">
            Save
          </button>
          <button
            type="button"
            class="fil-btn"
            id="cancel-button"
            v-on:click="CloseResultpopup()"
            style="width: 20%; border-radius: 5px">
            Cancel
          </button>
        </div>
      </Form>
    </TextModal>
    <DeleteModal @remove="deleteRecord($event)" ref="deleteModal" />
  </div>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
import DeleteModal from "@/components/DeleteModal";
// import Pagination from "@/components/Pagination";
import TextModal from "@/components/TextModal";

export default {
  name: "Admin",
  components: {
    ErrorComponent,
    DeleteModal,
    // Pagination,
    TextModal,
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      accessObject: this.$helperService.getAccessLevel("Manage staff"),
      loader: true,
      isShowAccessDeniedMessage: false,
      searchObj: {
        keyword: "",
      },
      resultDetail: {
        message: "",
      },
      offset: -1,
      list: [],
      type: "",
      feedback: {},
    };
  },
  watch: {
    "$route.fullPath"(newValue) {
      this.pageName = newValue.replace("/", "");
      this.$helperService.showMenu("true");
      this.getList(0);
    },
  },
  mounted() {
    this.$helperService.showMenu("true");
    this.getList(0);
  },
  methods: {
    showCommentPopup() {
      this.$refs.CommentModel.showModal();
      this.$refs.commentForm.resetForm();
    },
    CloseResultpopup() {
      this.$refs.CommentModel.closeModal();
      this.$refs.commentForm.resetForm();
    },
    addComment() {
      if (this.id && this.resultDetail.message) {
        var obj = this.resultDetail;
        obj.fid = this.id;
        this.$api
          .postAPI({
            _action: "/add-comments",
            _body: obj,
            _buttonId: "save-button",
          })
          .then((res) => {
            this.$notify({
              type: "success",
              text: res.message,
            });
            this.CloseResultpopup();
            this.getList(0, "TYPE");
          })
          .catch(() => {});
      }
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page, orderBy, sortBy, type) {
      if (!type) {
        this.$refs.errorComponent.updateListLoader(true);
      }
      this.list = [];
      this.searchObj.offset = page;
      if (orderBy) {
        this.searchObj.order_by = orderBy;
      }
      if (sortBy) {
        this.searchObj.sort_by = sortBy;
      }
      this.searchObj.id = this.id;
      this.$api
        .getAPI({
          _action: "/feedbacks-comments-pagination-list",
          _body: this.searchObj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.feedback = res.feedback;
          // this.offset = res.next_offset;
          // if (page == 0) {
          //   if (this.$refs.listPagination) {
          //     if (res.count != null && res.count > 0) {
          //       this.$refs.listPagination.setTotalCount(res.count);
          //     } else {
          //       this.$refs.listPagination.setTotalCount(0);
          //     }
          //   }
          // }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(
              res,
              "No Feedback List Available"
            );
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
    sorting(sortBy, id) {
      var obj = this.$helperService.sorting(sortBy, id);
      this.getList(0, obj.order_by, obj.sort_by);
    },
    resetFilter() {
      this.searchObj.keyword = "";
      this.getList(0);
    },
    showDeletePopup(data) {
      this.$refs.deleteModal.showModal(
        "Delete Admin",
        "Are you sure you want to delete " + data.name,
        data
      );
    },
    deleteRecord(dataObj) {
      var detail = new Proxy(dataObj, {});
      this.$api
        .deleteAPI({
          _action: "/delete/" + detail.uuid,
        })
        .then((res) => {
          if (res) {
            this.$refs.deleteModal.closeModal();
            this.getList();
            this.$notify({
              type: "success",
              text: "Admin deleted successfully",
            });
          }
        })
        .catch(() => {
          this.$refs.deleteModal.removeLoader();
        });
    },
  },
};
</script>
