<template>
  <div>
    <div class="page-wrapper">
      <div class="page-content-wrapper">
        <div class="shadow-btm">
          <div class="row">
            <div
              class="col-lg-12 col-sm-12 my-auto d-flex align-items-center mb-4">
              <a
                class="pr-2 text-dark cursor-pointer"
                v-on:click="$router.go(-1)"
                ><span class="font-24 mr-2"
                  ><img src="/images/back-arrow.svg" width="18"
                /></span>
              </a>
              <h4 class="mb-0 lg-bld">{{ id ? "View" : "Add" }} Profile</h4>
            </div>
          </div>
        </div>
        <div class="page-content">
          <div class="card-body py-0 px-0">
            <div class="row">
              <div class="col-lg-12 col-sm-10 float-none">
                <div class="card border mb-0 radius-15">
                  <div class="card-body">
                    <Form itemref="adminForm" @submit="save()">
                      <div class="row">
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Employee Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="name"
                              class="form-control"
                              placeholder="Enter Full Name"
                              v-model="detail.employee_name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="name" class="text-danger" /> -->
                            <!-- rules="required:Name" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Department</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="department"
                              class="form-control"
                              placeholder="Enter Department"
                              v-model="detail.department"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage -->
                            <!-- rules="required:department" -->
                            <!-- name="department"
                              class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Designation</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="designation"
                              class="form-control"
                              placeholder="Enter Designation"
                              v-model="detail.designation"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage -->
                            <!-- rules="required:designation" -->
                            <!-- name="designation"
                              class="text-danger" /> -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>PF NO</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="pf_no"
                              class="form-control"
                              placeholder="Enter PF No"
                              v-model="detail.pf_no"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="pf_no" class="text-danger" /> -->
                            <!-- rules="required:pf no" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Father Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="father_name"
                              class="form-control"
                              placeholder="Enter Father Name"
                              v-model="detail.father_name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="father_name" class="text-danger" /> -->
                            <!-- rules="required:father name" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Birth</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="date_of_birth"
                              class="form-control"
                              placeholder="Enter Date Of Birth"
                              v-model="detail.date_of_birth"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="date_of_birth" class="text-danger" /> -->
                            <!-- rules="required:date of birth" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Nominee Name</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Nominee Name"
                              class="form-control"
                              placeholder="Enter Nominee Name"
                              v-model="detail.nominee_name"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Nominee Name" class="text-danger" /> -->
                            <!-- rules="required:Nominee Name" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Nominee Mobile</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Nominee Mobile"
                              class="form-control"
                              placeholder="Enter Nominee Mobile"
                              v-model="detail.nominee_mobile"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Nominee Mobile" class="text-danger" /> -->
                            <!-- rules="required:Nominee Mobile" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Relation Nominee</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Relation Nominee"
                              class="form-control"
                              placeholder="Enter Relation Nominee"
                              v-model="detail.relation_nominee"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Relation Nominee" class="text-danger" /> -->
                            <!-- rules="required:Relation Nominee" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Landmark</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Landmark"
                              class="form-control"
                              placeholder="Enter Landmark"
                              v-model="detail.landmark"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Landmark" class="text-danger" /> -->
                            <!-- rules="required:Landmark" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Address</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Address"
                              class="form-control"
                              placeholder="Enter Address"
                              v-model="detail.address"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Address" class="text-danger" /> -->
                            <!-- rules="required:Address" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>City</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="City"
                              class="form-control"
                              placeholder="Enter City"
                              v-model="detail.city"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="City" class="text-danger" /> -->
                            <!-- rules="required:City" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>State</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="State"
                              class="form-control"
                              placeholder="Enter State"
                              v-model="detail.state"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="State" class="text-danger" /> -->
                            <!-- rules="required:State" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Pincode</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Pincode"
                              class="form-control"
                              placeholder="Enter Pincode"
                              v-model="detail.pincode"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Pincode" class="text-danger" /> -->
                            <!-- rules="required:Pincode" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>PAN</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Pan Card"
                              class="form-control"
                              placeholder="Enter Pan Card"
                              v-model="detail.pan_card"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Pan Card" class="text-danger" /> -->
                            <!-- rules="required:Pan Card" -->
                          </div>
                        </div>
                      
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Email</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Email"
                              class="form-control"
                              placeholder="Enter Email"
                              v-model="detail.email"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Email" class="text-danger" /> -->
                            <!-- rules="required:Email" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Demise</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Date Of Demise"
                              class="form-control"
                              placeholder="Enter Date Of Demise"
                              v-model="detail.date_of_demise"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Date Of Demise" class="text-danger" /> -->
                            <!-- rules="required:Date Of Demise" -->
                          </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                          <div class="form-group">
                            <label>Date Of Jonning</label>
                            <!-- <span class="text-danger">*</span> -->
                            <Field
                              type="text"
                              name="Date Of Jonning"
                              class="form-control"
                              placeholder="Enter Date Of Jonning"
                              v-model="detail.date_of_jonning"
                              :validateOnInput="true" />
                            <!-- <ErrorMessage name="Date Of Jonning" class="text-danger" /> -->
                            <!-- rules="required:Date Of Jonning" -->
                          </div>
                        </div>
                        <div class="col-12" style="margin-left: -11px">
                          <div class="col-lg-2 col-sm-2 mt-3">
                            <label>Send Condolence Message</label>
                            <div class="form-group">
                              <!-- <span class="text-danger">*</span> -->
                              <Field
                                type="checkbox"
                                name="is_send_condolence_message"
                                v-model="detail.is_send_condolence_message"
                                :validateOnInput="true" />
                              <!-- <ErrorMessage name="State" class="text-danger" /> -->
                              <!-- rules="required:State" -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12" style="margin-left: -11px">
                          <div class="col-lg-12 col-sm-12 mt-3">
                            <label>Document Submitted</label>
                            <div class="form-group">
                              <!-- <span class="text-danger">*</span> -->
                              <Field
                                as="textarea"
                                name="document_submitted"
                                class="form-control"
                                style="height: 100px !important"
                                v-model="detail.document_submitted"
                                :validateOnInput="true" />
                              <!-- <ErrorMessage name="State" class="text-danger" /> -->
                              <!-- rules="required:State" -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12" style="margin-left: -11px">
                          <div class="col-lg-2 col-sm-2 mt-3">
                            <label>Document Completed</label>
                            <div class="form-group">
                              <!-- <span class="text-danger">*</span> -->
                              <Field
                                type="checkbox"
                                name="is_document_completed"
                                v-model="detail.is_document_completed"
                                :validateOnInput="true" />
                              <!-- <ErrorMessage name="State" class="text-danger" /> -->
                              <!-- rules="required:State" -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12" style="margin-left: -11px">
                          <div class="col-lg-12 col-sm-12 mt-3">
                            <label>Document Not Submitted</label>
                            <div class="form-group">
                              <!-- <span class="text-danger">*</span> -->
                              <Field
                                as="textarea"
                                name="document_submitted"
                                class="form-control"
                                style="height: 100px !important"
                                v-model="detail.document_not_submitted"
                                :validateOnInput="true" />
                              <!-- <ErrorMessage name="State" class="text-danger" /> -->
                              <!-- rules="required:State" -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12" style="margin-left: -11px">
                          <div class="col-lg-2 col-sm-2 mt-3">
                            <label>Document Not Completed</label>
                            <div class="form-group">
                              <!-- <span class="text-danger">*</span> -->
                              <Field
                                type="checkbox"
                                name="is_document_completed"
                                v-model="detail.is_not_document_completed"
                                :validateOnInput="true" />
                              <!-- <ErrorMessage name="State" class="text-danger" /> -->
                              <!-- rules="required:State" -->
                            </div>
                          </div>
                        </div>
                        <div class="col-12 p-3 mb-4" style="margin-left: -11px">
                          <img
                            :src="
                              detail.selfi_avtar && detail.selfi_avtar != null
                                ? detail.selfi_avtar
                                : '/images/placeholder.gif'
                            "
                            id="preview"
                            style="width: 150px; height: 150px" /><br />
                          <div id="msg"></div>
                          <div id="image-form">
                            <input
                              type="file"
                              name="img[]"
                              class="file"
                              id="pickFile"
                              @change="selectedFile($event)"
                              accept="image/*"
                              style="display: none" />
                            <div class="input-group my-1">
                              <input
                                type="text"
                                class="form-control"
                                disabled
                                placeholder="Upload File"
                                id="file"
                                style="display: none" />
                              <div class="changePic" style="bottom: -25px">
                                <label
                                  for="pickFile"
                                  style="color: white !important; height: 40px">
                                  Upload Photo</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-lg-12 col-sm-12 text-center mt-2">
                          <button id="save-button" class="fill-btn px-4 ml-3">
                            Save
                          </button>
                          <button
                            type="button"
                            class="fil-btn px-4 ml-3"
                            v-on:click="$router.go(-1)"
                            id="cancel-button">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ErrorComponent @retry="getDetail()" ref="errorComponent" />
</template>
<script>
import { Form, Field } from "vee-validate";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "AddAdmin",
  components: {
    Form,
    Field,
    ErrorComponent,
    // ErrorMessage,
  },
  data() {
    return {
      id: this.$route.params.id,
      roleList: [],
      detail: {
        password: "",
        is_document_completed: false,
        is_send_condolence_message: false,
      },
      passwordFieldType: "password",
      passwordFieldType2: "password",
      image: "/images/show.png",
      images: "/images/show.png",
      user_type: "",
      file: null,
    };
  },
  mounted() {
    this.$helperService.showMenu("true");
    if (this.id) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.$refs.errorComponent.updateFormLoader(true);
      this.$api
        .getAPI({
          _action: "/grievance/" + this.id,
        })
        .then((res) => {
          if (res && res.result) {
            this.detail = res.result;
          }
          this.$refs.errorComponent.updateFormLoader(false);
        })
        .catch(() => {
          this.$refs.errorComponent.updateFormLoader(false);
        });
    },
    save() {
      if (this.id) {
        this.$api
          .putAPI({
            _action: "/grievance",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.file && res.gid) {
              this.uploadImage(res.gid);
              return;
            }
            this.$router.go(-1);
          })
          .catch(() => {});
      } else {
        this.$api
          .postAPI({
            _action: "/grievance",
            _body: this.detail,
            _buttonId: "save-button",
          })
          .then((res) => {
            if (this.file && res.gid) {
              this.uploadImage(res.gid);
              return;
            }
            this.$router.go(-1);
          })
          .catch(() => {});
      }
    },
    selectedFile(e) {
      let files = e.target.files;
      if (
        files[0].type.toLowerCase() != "image/jpeg" &&
        files[0].type.toLowerCase() != "image/png"
      ) {
        alert("Invalid file format please use jpeg or png file.");
        return;
      }
      const size = (files[0].size / 1024 / 1024).toFixed(2);
      // var size = e.target.files[0].size / 1024 / 1024
      if (size > 1) {
        this.$notify({
          type: "error",
          text: "File must be less then 1 MB",
        });
        return;
      }
      var tmppath = URL.createObjectURL(files[0]);
      this.file = files[0];
      document.getElementById("preview").src = tmppath;
    },
    uploadImage(gid) {
      if (!this.file) {
        this.$router.go(-1);
        return;
      }
      this.$api
        .uploadImageAPI({
          _action: "/upload-selfi",
          _key: "image",
          _file: this.file,
          _body: {
            gid: gid,
          },
          _buttonId: "save-button",
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
};
</script>
